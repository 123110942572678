.App {
  position: relative;
  min-height: 100vh;
  text-align: center;
  background-color: #282c34;
  color: antiquewhite;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: antiquewhite;
  text-decoration: underline;
}

.App-Intro{
  padding-bottom: 1rem;
  align-items: center;
  margin-left: 30%;
  margin-right: 30%;
}

.line{
  width: 95%;
}

.excel{
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: larger;
}


.App-footer{
  font-style: italic;
  bottom: 0%;
  height: 10;
  width: 100%;
  padding-bottom: 2.5rem;
  padding-top: 1rem;
}

.App-link {
  color: #61dafb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
